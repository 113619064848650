<template>
  <div class="home">
    <section id="home" class="hero_area home-div">
      <!-- slider section -->
      <mainHeader />
      <sectionSlider />
      <!-- end slider section -->
    </section>
    <section id="services" class="services home-div">
      <div class="container">
        <div>
          <h2>{{ $t('home.services.heading') }}</h2>
          <p>{{ $t('home.services.p1') }}</p>
          <p>{{ $t('home.services.p2') }}</p>
        </div>
      </div>
    </section>
    <!-- why us section -->
    <section id="why-us" class="why_us_section layout_padding home-div why-us">
      <div class="container">
        <div class="row">
          <h1>Why us ?</h1>
          <div id="plan" class="hover_content_block col-sm-4">
            <div class="overlay-darker"></div>
            <div class="overlay"></div>
            <div class="Hover_con_text">
              <i class="Hover_con_icon fa fa-lightbulb-o"></i>
              <h2>Plan</h2>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the
                blind texts.</p>
              <!--<p><a href="#" class="btn btn-primary">Get In Touch</a></p>-->
            </div>
          </div>
          <div id="develop" class="hover_content_block col-sm-4 col2">
            <div class="overlay-darker"></div>
            <div class="overlay"></div>
            <div class="Hover_con_text">
              <i class="Hover_con_icon fa fa-wrench"></i>
              <h2>Develop</h2>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the
                blind texts.</p>
              <!--<p><a href="#" class="btn btn-primary">Click Me</a></p>-->
            </div>
          </div>
          <div id="launch" class="hover_content_block col-sm-4 col3">
            <div class="overlay-darker"></div>
            <div class="overlay"></div>
            <div class="Hover_con_text">
              <i class="Hover_con_icon fa fa-rocket"></i>
              <h2>Launch</h2>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the
                blind texts.</p>
              <!--<p><a href="#" class="btn btn-primary">Why Us?</a></p>-->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end why us section -->
    <!-- about section -->
    <section id="about" class="about home-div">
      <div class="container  ">
        <div>
          <h2>{{ $t('home.about.heading') }}</h2>
          <p>{{ $t('home.about.p1') }}</p>
          <p>{{ $t('home.about.p2') }}</p>
        </div>
      </div>
    </section>
    <!-- end about section -->
    <!-- contact section -->
    <section id="contact-us" class="contact-us contact_section layout_padding-bottom home-div">
      <div class="container-fluid">
        <div class="row">
          <div class="contact-form col-lg-4 col-md-5 offset-md-1">
            <div class="form_container">
              <h2>
                {{ $t('home.contact.heading') }}
              </h2>
              <form action="">
                <div>
                  <input type="text" :placeholder="$t('home.contact.yourName')" />
                </div>
                <div>
                  <input type="text" :placeholder="$t('home.contact.phoneNumber')" />
                </div>
                <div>
                  <input type="email" :placeholder="$t('home.contact.email')" />
                </div>
                <div>
                  <input type="text" :placeholder="$t('home.contact.message')" />
                </div>
                <div class="btn_box">
                  <button>
                    {{ $t('home.contact.sendBtn') }}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-7 col-md-6 px-0">
            <div class="map_container">
              <div class="map">
                <div id="googleMap">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3007.154861707884!2d28.628111076465633!3d41.087462914544766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b558ff165563b9%3A0x9b0b4f10d6b30ca!2zS2FyYWFnYcOnLCBIYWTEsW1rw7Z5LcSwc3RhbmJ1bCBDZC4gTm86MTQsIDM0NTAwIELDvHnDvGvDp2VrbWVjZS_EsHN0YW5idWw!5e0!3m2!1str!2str!4v1690452969804!5m2!1str!2str"
                    width="600" height="450" style="border:0;" allow="fullscreen" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade">
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import mainHeader from '@/components/main/mainHeader.vue'
import sectionSlider from '@/components/main/sectionSlider'
export default {
  components: {
    mainHeader,
    sectionSlider
  },
  mounted () {
    this.adjustDivHeight()
  },
  methods: {
    adjustDivHeight () {
      const windowHeight = window.innerHeight
      // const windowWidth = window.screen.width
      const divElements = document.querySelectorAll('.home-div')
      divElements.forEach(function (div) {
        div.style.height = windowHeight + 'px'
        // div.style.width = windowWidth + 'px'
      })
    }
  },
  created () {
    window.addEventListener('resize', this.adjustDivHeight)
  }
}
</script>
<style scoped>
.home {
  width: 100%;
}

.services h2,
.about h2 {
  color: #3673a4;
  font-size: 36px;
  margin-bottom: 25px;
}

.services .container,
.about .container {
  height: 100%;
  font-family: auto;
}
</style>
