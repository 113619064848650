import axios from 'axios'
import VueJwtDecode from 'vue-jwt-decode'
import app from '@/main'
import router from '@/router/index'; // Vue Router'ı içe aktarın

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(config => {
  const token = app.config.globalProperties.$cookies.get('authToken')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
    config.headers['Accept-Language'] = determineLanguage()
    const currentTimestamp = Math.floor(Date.now() / 1000)
    if (currentTimestamp > VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).exp) {
      app.config.globalProperties.$cookies.remove('authToken')
      router.push('login')
    }
  }
  return config;
}, error => {
  return Promise.reject(error)
});

axiosInstance.interceptors.response.use(response => {
  return response
}, error => {
  return Promise.reject(error)
});

function determineLanguage() {
  if (app.config.globalProperties.$cookies.get('lang')) {
    return app.config.globalProperties.$cookies.get('lang')
  } else {
    return 'en'
  }
}

export default axiosInstance
